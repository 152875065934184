import { Check, CircleX } from "lucide-react";
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from "react";

import { useAppSelector } from "src/utils/redux";
import { SeverityIndicator } from "@repo/ping-react-js";
import { useHotkeys } from "react-hotkeys-hook";
import { LucideIconName, PingLucideIcon } from "@repo/ping-react-js";

import { PVSectionSubHeading } from "features/submission-dashboard/PVHeadings";
import { PVSubmissionDetailsToolbar } from "features/submission-dashboard/PVSubmissionDetailsToolbar";
import {
  HeadingSchemaOptions,
  PingRichTextInputField,
} from "features/submission-dashboard/PingRichTextInputField";
import { PVInsuredInformationForm } from "features/submission-dashboard/PVInsuredInformationForm";
import { PVBrokerInformationGrid } from "features/submission-dashboard/PVBrokerInformationGrid";
import { PVSOVReview } from "features/submission-dashboard/PVSOVReview";
import PVAttachmentsTable from "features/submission-dashboard/PVAttachmentsTable";
import PVJobsTable from "features/submission-dashboard/PVJobsTable";

import {
  PVAllDocumentsPreviewPanel,
  DocumentType,
} from "features/submission-dashboard/PVAllDocumentsPreviewPanel";
import { PVSubmissionPill } from "features/submission-dashboard/PVSubmissionPill";
import PVNotes from "features/submission-dashboard/PVNotes";
import PingVisionMapLite from "features/submission-dashboard/PingVisionMapLite";
import { PingOpenAiView } from "features/submission-dashboard/PVThirdPartyDataView";
import { PingVisionSubmissionActivityItem } from "features/submission-dashboard/PingVisionSubmissionActivityItem";

import {
  PVScrollSpyProvider,
  PVScrollSpySection,
  PVScrollSpySections,
  PVScrollSpySectionWrapper,
  PVScrollSpyTabBar,
} from "features/submission-dashboard/PVScrollSpy";
import { useUpdateSubmissionMutation } from "services/pvSlice";

import { findSubmissionTitle, getFullTeamName } from "utils/submission";
import { SovDataType } from "ts-types/DataTypes";
import { ActivityItemType } from "ts-types/ApiTypes";
import { useSubmissionHistory } from "./useSubmissionHistory";
import PingUploadWrapper from "features/PingUploadWrapper";
import {
  getHumanFriendlyDataReadinessScore,
  getHumanFriendlyTriageScore,
} from "utils/submission";
import { usePingVisionUrlStore } from "features/usePingVisionUrlStore";

import "./PVSubmissionDetails.scss";

type PVSubmissionDetailsProps = {
  selectedItem: SovDataType;
  setShowArchivedDocuments: Dispatch<SetStateAction<boolean>>;
  onPreviewPanelCollapse: () => void;
  isPreviewPanelCollapsed: boolean;
  setInitialPreviewType: Dispatch<SetStateAction<DocumentType | null>>;
  hasPreviewableDocuments: boolean;
};

const HEADING_SCHEMA_OPTIONS: HeadingSchemaOptions = {
  type: "heading",
  level: 2,
};

const iconSize = 24;

export const PVSubmissionDetails: FC<PVSubmissionDetailsProps> = ({
  selectedItem,
  hasPreviewableDocuments,
  isPreviewPanelCollapsed,
  onPreviewPanelCollapse,
  setShowArchivedDocuments,
  setInitialPreviewType,
}) => {
  const { setMode } = usePingVisionUrlStore();

  const submissionHistory: ActivityItemType[] = useAppSelector(
    (state) => state.inbox.submissionHistory,
  );

  useHotkeys("esc", () => {
    setMode(null);
  });

  const mergedHistory = useSubmissionHistory({ submissionHistory });

  const [updateSubmission] = useUpdateSubmissionMutation();

  useEffect(() => {
    if (selectedItem.automated_processing_failed) {
      // scroll to top of attachments table so people can fix the SOV processing issue
      const attachmentsSection = document.querySelector(
        ".PVSubmissionDetails__AttachmentsHeader",
      );
      if (attachmentsSection) {
        attachmentsSection.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // ensure scroll position is at the top of the new selectedItem, default behavior
      const mainContainer = document.querySelector(".PVSubmissionDetails");
      if (mainContainer) {
        mainContainer.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [selectedItem.id]);

  const onTitleBlur = useCallback(
    (newContent: string) => {
      updateSubmission({
        id: selectedItem.id,
        data: {
          insured_name: newContent,
        },
      });
    },
    [selectedItem, updateSubmission],
  );

  const readiness = selectedItem?.data_readiness_notes?.filter(
    (_) => _.severity !== "debug",
  );
  if (
    selectedItem.data_readiness_notes?.length &&
    selectedItem.data_readiness_notes?.length > 0 &&
    readiness?.length === 0
  ) {
    readiness.push({
      severity: "info",
      reason: "No data readiness notes available",
      value: 1.0,
    });
  }

  const groupedNotes = [];
  let currentCaption = null;
  for (const note of readiness ?? []) {
    const currentGroupCaption = note.sheet || "General";
    if (!currentCaption || currentCaption.caption !== currentGroupCaption) {
      currentCaption = { caption: currentGroupCaption, notes: [] };
      groupedNotes.push(currentCaption);
    }
    currentCaption.notes.push(note);
  }

  const triageOverallResultData = getHumanFriendlyTriageScore(
    selectedItem?.triage_rules_overall_result,
    selectedItem.is_ping_ready,
  );

  const readinessScoreData = getHumanFriendlyDataReadinessScore(
    selectedItem?.data_readiness_score,
  );

  const getRuleResultDisplay = (result: string) => {
    const size = 18;
    switch (result) {
      case "pass":
        return <Check size={size} color="green" />;
      case "fail":
        return <CircleX size={size} color="red" />;
      case "warning":
        return <CircleX size={size} color="yellow" />;
      case "exception":
        return <CircleX size={size} color="brightred" />;
      default:
        return <CircleX size={size} color="gray" />;
    }
  };

  return (
    <PVScrollSpyProvider offsetPx={165}>
      <div className="PVSubmissionDetails">
        <header className="PVSubmissionDetails__Header">
          <PVSubmissionDetailsToolbar selectedItem={selectedItem} />
          <div className="PVSubmissionDetails__Header__Metadata">
            {selectedItem.automated_processing_failed && (
              <div className="PVSubmissionDetails__Header__Metadata__Item PVSubmissionDetails__Header__Metadata__Item--error">
                <button
                  onClick={() => {
                    const attachmentsSection = document.querySelector(
                      ".PVSubmissionDetails__AttachmentsHeader",
                    );
                    if (attachmentsSection) {
                      attachmentsSection.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  SOV processing failed
                </button>
              </div>
            )}
            <div className="PVSubmissionDetails__Header__Metadata__Item">
              {selectedItem.id}
            </div>
            <div className="PVSubmissionDetails__Header__Metadata__Item">
              {getFullTeamName(selectedItem)}
            </div>
          </div>
          <div className="PVSubmissionDetails__Header__InsuredEditorContainer">
            <PingRichTextInputField
              key={`${selectedItem.id}-${selectedItem.insured_name}`}
              schemaOptions={HEADING_SCHEMA_OPTIONS}
              initialContent={findSubmissionTitle(selectedItem) || ""}
              onBlur={onTitleBlur}
              className="PVSubmissionDetails__Header__InsuredEditorContainer__Editor"
            />
            <PVSubmissionPill sov={selectedItem} tooltipPlacement="bottom" />
          </div>
          <PVScrollSpyTabBar />
          {isPreviewPanelCollapsed && hasPreviewableDocuments && (
            <PVAllDocumentsPreviewPanel
              selectedItem={selectedItem}
              setInitialPreviewType={setInitialPreviewType}
              isCollapsed={isPreviewPanelCollapsed}
              onSetShowArchived={setShowArchivedDocuments}
              onCollapseExpand={onPreviewPanelCollapse}
            />
          )}
        </header>

        <PVScrollSpySections className="PVSubmissionDetails__Content">
          <PVScrollSpySection title="Locations">
            <PingVisionMapLite selectedItem={selectedItem} />
          </PVScrollSpySection>

          <PVScrollSpySectionWrapper title="Insured Information">
            <PVInsuredInformationForm selectedItem={selectedItem} />
          </PVScrollSpySectionWrapper>

          <PVScrollSpySectionWrapper title="Broker Information">
            <PVBrokerInformationGrid selectedItem={selectedItem} />
          </PVScrollSpySectionWrapper>

          <PVScrollSpySectionWrapper title="SOV Review">
            <PVSOVReview selectedItem={selectedItem} />
          </PVScrollSpySectionWrapper>

          <PVScrollSpySection
            className="PVSubmissionDetails__AttachmentsHeader"
            title="Attachments"
            renderActions={() => (
              <PVAttachmentsTable.UploadButton selectedItem={selectedItem} />
            )}
          >
            <PingUploadWrapper>
              <PVAttachmentsTable selectedItem={selectedItem} />
            </PingUploadWrapper>
          </PVScrollSpySection>

          <PVScrollSpySection title="Jobs">
            <PingUploadWrapper>
              <PVJobsTable selectedItem={selectedItem} />
            </PingUploadWrapper>
          </PVScrollSpySection>

          {/* {skunkWorksAccess && (
            <PVScrollSpySection title="BrightQuery">
              <PingBrightQueryView selectedItem={selectedItem} />
            </PVScrollSpySection>
          )} */}

          <PVScrollSpySection title="AI Overview">
            <PingOpenAiView selectedItem={selectedItem} />
          </PVScrollSpySection>

          {groupedNotes.length > 0 && (
            <PVScrollSpySection
              title="Data Readiness"
              {...(readinessScoreData && {
                titleNode: (
                  <div className="PVSubmissionDetails__DataReadiness__TitleWrapper">
                    <PingLucideIcon
                      className="PVSubmissionDetails__DataReadiness__Icon"
                      iconName={readinessScoreData.icon as LucideIconName}
                      size={iconSize}
                      color={readinessScoreData.color}
                    />
                    Data Readiness: {readinessScoreData.label}
                  </div>
                ),
              })}
            >
              <ul className="PVSubmissionDetails__DataReadiness__Group">
                {groupedNotes.map((grouped_note, index2) => (
                  <li key={index2}>
                    <PVSectionSubHeading>
                      {grouped_note.caption}
                    </PVSectionSubHeading>
                    <ul className="PVSubmissionDetails__DataReadiness__Subgroup">
                      {grouped_note.notes.map((note, index) => (
                        <li key={index}>
                          {" "}
                          <SeverityIndicator readinessScore={note?.value} />
                          <span>{note.reason}</span>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </PVScrollSpySection>
          )}

          {selectedItem.triage_rule_results?.length > 0 && (
            <PVScrollSpySection
              title="Submission Triage"
              {...(triageOverallResultData && {
                titleNode: (
                  <div className="PVSubmissionDetails__SubmissionTriage__TitleWrapper">
                    <PingLucideIcon
                      iconName={triageOverallResultData.icon as LucideIconName}
                      size={iconSize}
                      color={triageOverallResultData.color}
                    />
                    Recommendation: {triageOverallResultData.label}
                  </div>
                ),
              })}
            >
              <table>
                {selectedItem.triage_rule_results.map((result, index) => (
                  <tr key={index}>
                    <td>{getRuleResultDisplay(result.result)}</td>
                    <td>{result.name}</td>
                    {/* <td>{result.value}</td> */}
                  </tr>
                ))}
              </table>
            </PVScrollSpySection>
          )}

          <PVScrollSpySection title="Activity">
            <ul className="PVSubmissionDetails__Activity__List">
              {mergedHistory?.map((historyItem, i) => {
                return (
                  <li key={i}>
                    <PingVisionSubmissionActivityItem
                      historyItem={historyItem}
                    />
                  </li>
                );
              })}
            </ul>
            <PVNotes selectedItem={selectedItem} />
          </PVScrollSpySection>
        </PVScrollSpySections>
      </div>
    </PVScrollSpyProvider>
  );
};
