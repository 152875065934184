import { useCallback, useEffect, useMemo, useState, useRef, memo } from "react";
import {
  hasWorkflowMismatch,
  isWorkflowStatusInInboxOrProgress,
} from "./workflowUtils";
import { useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { useMeasure } from "@uidotdev/usehooks";
import {
  PingSearchQueryBuilder,
  useSearchQueryBuilder,
} from "@repo/ping-react-js";
import {
  SEARCH_PARAM_NAME,
  useGetAdvancedSearchFields,
  useGetSubmissionList,
} from "features/submission-dashboard/queries";

import {
  LoadingMessagePanel,
  PingVirtualWindow,
  useAuth,
  PingButton,
} from "@repo/ping-react-js";

import { PingVisionSubmissionListItem } from "features/submission-dashboard/PingVisionSubmissionListItem";
import {
  updateMultiSelectToast,
  clearMultiSelectToast,
} from "features/submission-dashboard/PingMultiSelectOptions";
import { useAppDispatch, useAppSelector } from "utils/redux";
import { usePingId, useSlug, useTeamId } from "utils/hooks";
import {
  setIsCommandMenuOpened,
  setSelectedSubmissions,
} from "reducers/settings";
import { setCurrentCursorId, setSlug, setTeamId } from "reducers/inbox";
import { usePingVisionUrlStore } from "features/usePingVisionUrlStore";
import { APP_MODES } from "constants/SubmissionConstants";
import { SovDataType } from "ts-types/DataTypes";

import "./PingVisionSubmissionList.scss";

interface PingVisionSubmissionListProps {
  onSortChange: (newSortConfig: {
    field: "inception_date" | "created_time";
    direction: "asc" | "desc";
  }) => void;
  sortConfig: {
    field: "inception_date" | "created_time";
    direction: "asc" | "desc";
  };
}

export const PingVisionSubmissionList: React.FC<
  PingVisionSubmissionListProps
> = ({ onSortChange, sortConfig }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { setMode } = usePingVisionUrlStore();

  const selectedItemId = usePingId();
  const teamId = useTeamId();
  const navToQueryParams = useAppSelector(
    (state) => state.settings.navToQueryParams,
  );
  const { ssoUser } = useAuth();
  const matchingUserId = useAppSelector(
    (state) => state.settings?.envData?.user?.id,
  );
  const selectedSubmissions = useAppSelector(
    (state) => state.settings.selectedSubmissions,
  );
  const inboxSlug = useAppSelector((state) => state.inbox.slug);

  const { advancedSearchFields } = useGetAdvancedSearchFields();
  const fields = useMemo(() => {
    // For my-issues, we handle workflow status IDs through navToQueryParams combinations in
    // useGetSubmissonList in queries.ts,
    // so we don't want to show the workflow status field in the UI
    if (inboxSlug === "my-issues") {
      return advancedSearchFields.filter(
        (field) =>
          field.fieldName !== "workflow_status_id" &&
          field.fieldName !== "claimed_by_id",
      );
    }
    return advancedSearchFields;
  }, [advancedSearchFields, inboxSlug]);

  const { searchValues, setSearchValues, clearSearchValues } =
    useSearchQueryBuilder(fields, history, SEARCH_PARAM_NAME);

  const { data: sovData, isFetching: isFetchingSovData } = useGetSubmissionList(
    {
      ...(sortConfig?.field && sortConfig?.direction ? { sortConfig } : {}),
      searchValues,
    },
  );

  const totalResultsCount = sovData?.total_size_without_cursors;

  const workflowUpdates = useAppSelector(
    (state) => state.workflowUpdates.recentUpdates,
  );

  const hasClientStatusUpdate = useCallback(
    (submission) => {
      return workflowUpdates.some(
        (update) =>
          update.submissionId === submission.id &&
          (update.userId === ssoUser?.userId ||
            update.userId === matchingUserId?.toString()),
      );
    },
    [workflowUpdates, ssoUser, matchingUserId],
  );

  const checkWorkflowMismatch = useCallback(
    (workflowStatusId: number, currentInboxSlug: string | null): boolean => {
      return hasWorkflowMismatch(
        workflowStatusId,
        currentInboxSlug,
        navToQueryParams,
        teamId,
      );
    },
    [navToQueryParams, teamId],
  );

  const hasRemovableWorkflowUpdate = useCallback(
    (itemId: string) => {
      const item = sovData?.results?.find((sov) => sov.id === itemId);
      if (!item?.workflow_status_id || !inboxSlug) {
        return false;
      }

      const hasStatusMismatch = checkWorkflowMismatch(
        item.workflow_status_id,
        inboxSlug,
      );

      return hasStatusMismatch && itemId !== selectedItemId;
    },
    [sovData, inboxSlug, selectedItemId, checkWorkflowMismatch],
  );

  const remainingCount = sovData?.has_remaining;
  const nextCursorId = sovData?.cursor_id;

  const onSearch = useCallback(
    (data: Record<string, string> | null) => {
      if (!data) {
        clearSearchValues();
        dispatch(setCurrentCursorId(null));
        return;
      }

      setSearchValues(data);
      dispatch(setCurrentCursorId(null));
      setSearchValues(data);
    },
    [clearSearchValues, setSearchValues, dispatch],
  );

  const filteredSovs = useMemo(() => {
    if (inboxSlug === "all" || inboxSlug === null) {
      return sovData?.results || [];
    }

    if (inboxSlug === "my-issues") {
      return (sovData?.results || []).filter((submission) => {
        // Must be claimed by the user (if we have a matching user ID)
        if (matchingUserId && submission.claimed_by_id !== matchingUserId) {
          return false;
        }
        // Must be in an inbox or in-progress state
        return isWorkflowStatusInInboxOrProgress(
          submission.workflow_status_id,
          navToQueryParams,
        );
      });
    }

    const filterSubmission = (submission: SovDataType) => {
      return !hasRemovableWorkflowUpdate(submission.id);
    };

    return (sovData?.results || []).filter(filterSubmission);
  }, [
    navToQueryParams,
    sovData?.results,
    hasRemovableWorkflowUpdate,
    inboxSlug,
    matchingUserId,
  ]);

  const filteredResultsDiff = useMemo(() => {
    if (!sovData?.results) return 0;
    return sovData.results.length - filteredSovs.length;
  }, [sovData?.results, filteredSovs]);

  const adjustedResultsCount = useMemo(() => {
    if (!totalResultsCount) return "0";
    if (totalResultsCount === "100+") return totalResultsCount;

    const count = parseInt(totalResultsCount, 10);
    if (isNaN(count)) return totalResultsCount;

    return filteredResultsDiff > 0
      ? (count - filteredResultsDiff).toString()
      : totalResultsCount;
  }, [totalResultsCount, filteredResultsDiff]);

  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [isShiftDown, setIsShiftDown] = useState<boolean>(false);
  const [lastSelectedItem, setLastSelectedItem] = useState<string | null>(null);

  const slug = useSlug();

  const prevSelectedItemsSize = useRef(0);

  useEffect(() => {
    if (
      !selectedSubmissions?.length &&
      selectedItems.size &&
      prevSelectedItemsSize.current > 0
    ) {
      setSelectedItems(new Set());
    }
    prevSelectedItemsSize.current = selectedItems.size;
  }, [selectedSubmissions, selectedItems]);

  useEffect(() => {
    if (slug) {
      dispatch(setSlug(slug));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (teamId) {
      dispatch(setTeamId(teamId));
    }
  }, [dispatch, teamId]);

  useEffect(() => {
    if (selectedItems.size) {
      dispatch(setSelectedSubmissions([...selectedItems]));
      updateMultiSelectToast(
        selectedItems,
        () => {
          setSelectedItems(new Set());
        },
        () => {
          dispatch(setIsCommandMenuOpened(true));
        },
        () => {},
      );
    } else {
      clearMultiSelectToast();
      dispatch(setSelectedSubmissions([]));
    }
  }, [selectedItems, dispatch]);

  useEffect(() => {
    onSortChange({
      field: "created_time",
      direction: "desc",
    });
  }, [inboxSlug, onSortChange, teamId]);

  const handleCheckboxChange = useCallback(
    (id: string, checked: boolean) => {
      setSelectedItems((prevSelected) => {
        const newSelected = new Set(prevSelected);
        const results = sovData?.results || [];

        if (isShiftDown && lastSelectedItem && checked && results.length > 0) {
          const startIndex = results.findIndex(
            (sov) => sov?.id === lastSelectedItem,
          );
          const endIndex = results.findIndex((sov) => sov?.id === id);

          // Only proceed if we found both indices
          if (startIndex !== -1 && endIndex !== -1) {
            const [lower, upper] = [startIndex, endIndex].sort((a, b) => a - b);

            for (let i = lower; i <= upper; i++) {
              const item = results[i];
              if (item?.id) {
                newSelected.add(item.id);
              }
            }
          } else {
            // If indices not found, just add the current item
            newSelected.add(id);
          }
        } else {
          if (checked) {
            newSelected.add(id);
          } else {
            newSelected.delete(id);
          }
        }

        setLastSelectedItem(checked ? id : null);
        return newSelected;
      });
    },
    [isShiftDown, lastSelectedItem, sovData?.results],
  );

  const onClickIncomingItem = useCallback(
    (id: string) => {
      if (!teamId && !slug) {
        return;
      }
      if (isShiftDown) {
        handleCheckboxChange(id, !selectedItems.has(id));
        return;
      }

      const currentSearchParams = new URLSearchParams(window.location.search);
      currentSearchParams.set("selected", id);
      history.push(`${window.location.pathname}?${currentSearchParams}`);
    },
    [isShiftDown, teamId, history, slug, handleCheckboxChange, selectedItems],
  );

  const handleDoubleClickItem = useCallback(() => {
    if (isShiftDown) {
      return;
    }
    setMode(APP_MODES.DETAIL);
  }, [setMode, isShiftDown]);

  const currentIndex = sovData?.results?.findIndex(
    (s) => s.id === selectedItemId,
  );

  const selectNextSov = useCallback(() => {
    if (
      currentIndex !== undefined &&
      sovData?.results &&
      currentIndex < sovData.results.length - 1
    ) {
      const nextSov = sovData.results[currentIndex + 1];
      onClickIncomingItem(nextSov.id);
    }
  }, [currentIndex, sovData?.results, onClickIncomingItem]);

  const selectPreviousSov = useCallback(() => {
    if (currentIndex !== undefined && sovData?.results && currentIndex > 0) {
      const previousSov = sovData.results[currentIndex - 1];
      onClickIncomingItem(previousSov.id);
    }
  }, [currentIndex, sovData?.results, onClickIncomingItem]);

  useHotkeys("down", selectNextSov, [selectNextSov]);
  useHotkeys("up", selectPreviousSov, [selectPreviousSov]);
  useHotkeys("esc", () => {
    setSelectedItems(new Set());
    setLastSelectedItem(null);
  });

  useHotkeys(
    "shift",
    () => {
      setIsShiftDown(true);
    },
    { keyup: false, enableOnFormTags: ["input", "select", "textarea"] },
  );

  useHotkeys(
    "shift",
    () => {
      setIsShiftDown(false);
    },
    { keyup: true, enableOnFormTags: ["input", "select", "textarea"] },
  );

  const [submissionListRef, { height: submissionListHeight }] = useMeasure();

  const [resetScroll, setResetScroll] = useState(false);

  const prevValuesRef = useRef({
    sortConfig,
    inboxSlug,
    teamId,
  });

  useEffect(() => {
    const hasChanged =
      prevValuesRef.current.sortConfig.direction !== sortConfig.direction ||
      prevValuesRef.current.sortConfig.field !== sortConfig.field ||
      prevValuesRef.current.inboxSlug !== inboxSlug ||
      prevValuesRef.current.teamId !== teamId;

    if (hasChanged) {
      setSelectedItems(new Set());
      dispatch(setSelectedSubmissions([]));
      setResetScroll(true);

      // Update the ref with current values immediately
      prevValuesRef.current = {
        sortConfig,
        inboxSlug,
        teamId,
      };
    }

    // Reset the flag after a short delay to allow future sort changes to trigger scroll reset
    const timer = setTimeout(() => {
      if (resetScroll) {
        setResetScroll(false);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [dispatch, sortConfig, inboxSlug, teamId, resetScroll]);

  const handleSortClick = useCallback(
    (field: "inception_date" | "created_time") => {
      dispatch(setCurrentCursorId(null));
      onSortChange({
        field,
        direction:
          sortConfig.field === field
            ? sortConfig.direction === "asc"
              ? "desc"
              : "asc"
            : "asc",
      });
    },
    [dispatch, sortConfig.field, sortConfig.direction, onSortChange],
  );

  return (
    <div className="PingVisionSubmissionList__Container">
      <div className="PingVisionSubmissionList__Filters">
        <div className="PingVisionSubmissionList__ResultsCount">
          {adjustedResultsCount}{" "}
          {adjustedResultsCount === "1" ? "result" : "results"}
        </div>
        <div className="PingVisionSubmissionList__SortBySection">
          <span className="PingVisionSubmissionList__SortByText">Sort By:</span>
          <div className="PingVisionSubmissionList__FilterButtons">
            <PingButton
              className={`sort-button ${sortConfig.field === "created_time" ? "active" : ""}`}
              onClick={() => handleSortClick("created_time")}
              label={`Time Received ${sortConfig.field === "created_time" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}`}
            />
            <PingButton
              className={`sort-button ${sortConfig.field === "inception_date" ? "active" : ""}`}
              onClick={() => handleSortClick("inception_date")}
              label={`Inception Date ${sortConfig.field === "inception_date" ? (sortConfig.direction === "asc" ? "↑" : "↓") : ""}`}
            />
          </div>
        </div>
      </div>
      <div className="PingVisionSubmissionList__Search">
        <PingSearchQueryBuilder
          fields={fields}
          values={searchValues}
          onChange={onSearch}
        />
      </div>

      <ul className="PingVisionSubmissionList" ref={submissionListRef}>
        {submissionListHeight && (
          <PingVirtualWindow
            windowHeight={submissionListHeight}
            items={filteredSovs}
            resetScroll={resetScroll}
            renderItem={(item: SovDataType) => {
              const key = `sov-${item.id}-${selectedItems.has(item.id)}`;
              const hasMismatch =
                !isFetchingSovData &&
                inboxSlug &&
                item?.workflow_status_id &&
                !hasClientStatusUpdate(item) &&
                checkWorkflowMismatch(item?.workflow_status_id, inboxSlug);

              return (
                <PingVisionSubmissionListItem
                  itemIsChecked={selectedItems.has(item.id)}
                  key={key}
                  sov={item}
                  isSelected={selectedItemId === item.id}
                  onClickIncomingItem={onClickIncomingItem}
                  onCheckboxChange={handleCheckboxChange}
                  onDoubleClickItem={handleDoubleClickItem}
                  className={hasMismatch ? "workflow-status-mismatch" : ""}
                />
              );
            }}
            onEndReached={() => {
              if (nextCursorId && remainingCount && !isFetchingSovData) {
                dispatch(setCurrentCursorId(nextCursorId));
              }

              return Promise.resolve();
            }}
          />
        )}
        {isFetchingSovData && <LoadingMessagePanel />}
      </ul>
    </div>
  );
};
