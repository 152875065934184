import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "services/pvSlice.ts";

// Track workflow status updates, for knowing which are from the same user as the logged in user so that
// we can remove those from the submissions status list without prematurely invalidating the cache

interface WorkflowUpdate {
  submissionId: string;
  timestamp: number;
  workflowStatusId: string;
  userId: string;
}

interface WorkflowUpdatesState {
  recentUpdates: WorkflowUpdate[];
  maxUpdates: number;
  currentUserId: string | null;
}

const initialState: WorkflowUpdatesState = {
  recentUpdates: [],
  maxUpdates: 2000,
  currentUserId: null,
};

export const workflowUpdatesSlice = createSlice({
  name: "workflowUpdates",
  initialState,
  reducers: {
    addWorkflowUpdate: (state, action: PayloadAction<WorkflowUpdate>) => {
      const existingIndex = state.recentUpdates.findIndex(
        (update) => update.submissionId === action.payload.submissionId,
      );

      if (existingIndex !== -1) {
        state.recentUpdates.splice(existingIndex, 1);
      }

      state.recentUpdates.unshift(action.payload);

      if (state.recentUpdates.length > state.maxUpdates) {
        state.recentUpdates = state.recentUpdates.slice(0, state.maxUpdates);
      }
    },
    clearWorkflowUpdates: (state) => {
      state.recentUpdates = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getSubmissions.matchFulfilled, (state) => {
      // Clear recent updates when the submissions query is refetched
      state.recentUpdates = [];
    });
  },
});

export const { addWorkflowUpdate, clearWorkflowUpdates } =
  workflowUpdatesSlice.actions;
export default workflowUpdatesSlice.reducer;
