import { useMemo, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "src/utils/redux";
import omit from "lodash/omit";
import { useSlug, useTeamId, usePingId } from "utils/hooks";
import isFunction from "lodash/isFunction";

import {
  PingSearchQueryBuilderField,
  TEXT_SEGMENT_KEY,
} from "@repo/ping-react-js";

import { PING_VISION_DEFAULT_FIELDS } from "constants/ApiConstants";
import {
  useGetSubmissionsQuery,
  useGetSubmissionHistoryQuery,
  useGetSingleSubmissionQuery,
} from "services/pvSlice";
import { setCurrentCursorId } from "reducers/inbox";

export const SEARCH_PARAM_NAME = "search";

const getName = (t: Record<string, string>) => t.username || "";

export const useGetAdvancedSearchFields = (): {
  advancedSearchFields: PingSearchQueryBuilderField[];
} => {
  const settings = useAppSelector((state) => state.settings.settings);
  const advancedSearchFields: PingSearchQueryBuilderField[] = useMemo(() => {
    return [
      {
        label: "Readiness score",
        fieldName: "readiness",
        type: "range",
      },
      {
        label: "User",
        fieldName: "claimed_by_id",
        type: "dropdown",
        options: settings?.users?.map((t) => ({
          label: getName(t),
          value: t.id.toString(),
        })),
      },
      {
        label: "Workflow Status",
        fieldName: "workflow_status_id",
        type: "dropdown",
        options: settings?.submission_status?.map((s) => ({
          label: s.name,
          value: s.id.toString(),
        })),
      },
      {
        label: "Team Name",
        fieldName: "team_id",
        type: "dropdown",
        options: settings?.teams?.map((t) => ({
          label: t.team_name,
          value: t.team_id.toString(),
        })),
      },
    ];
  }, [settings]);

  return { advancedSearchFields };
};

export const useGetSubmissionHistoryList = () => {
  const pingId = usePingId();
  const settings = useAppSelector((state) => state.settings.settings);
  const teams = settings?.teams?.map((t) => t.team_id) || [];
  return useGetSubmissionHistoryQuery(
    { id: pingId || "", realTimeSubscriptions: { teams: teams || [] } },
    { skip: !pingId },
  );
};

export const useGetSubmissionList = ({
  sortConfig = {
    field: "created_time",
    direction: "desc",
  },
  searchValues = {},
}: {
  sortConfig?: {
    field: "inception_date" | "created_time";
    direction: "asc" | "desc";
  };
  searchValues?: Record<string, any>;
} = {}) => {
  // Note from Lori - right now I only figured out how to use this query in two places directly - the dashboard and list for submissions.
  // Other places take results from inbox.sovs or we risk having multiple server requests.

  const currentCursorId = useAppSelector(
    (state) => state.inbox.currentCursorId,
  );

  const navToQueryParams = useAppSelector(
    (state) => state.settings.navToQueryParams,
  );

  const userId =
    useAppSelector((state) => state.settings?.envData?.user?.id) || 0;

  const settings = useAppSelector((state) => state.settings.settings);

  // When we load the page, we pass all of the teams you're a member of to the
  // backend for real-time subscriptions. This allows the backend to send you
  // updates for all submissions via push.
  const teams = settings?.teams?.map((t) => t.team_id) || [];

  const slug = useSlug();
  const teamId = useTeamId();
  const pingId = usePingId();
  const dispatch = useAppDispatch();

  // Reset cursor when slug changes
  const slugRef = useRef(slug);
  const teamIdRef = useRef(teamId);

  useEffect(() => {
    if (slug !== slugRef.current) {
      // Reset cursor and update ref
      dispatch(setCurrentCursorId(null));
      slugRef.current = slug;
    }
  }, [slug, dispatch]);

  useEffect(() => {
    if (teamId !== teamIdRef.current) {
      // Reset cursor and update ref when team changes
      dispatch(setCurrentCursorId(null));
      teamIdRef.current = teamId;
    }
  }, [teamId, dispatch]);

  // Skip if teams aren't loaded or if navToQueryParams isn't populated yet
  const shouldSkip =
    !teams.length || Object.keys(navToQueryParams || {}).length === 0;

  const key = teamId ? `${teamId}-${slug}` : slug || "";

  // For my-issues slug, combine workflow_status_ids from in-progress and inbox keys
  let additionFilters = null;
  if (slug === "my-issues" && navToQueryParams) {
    const workflowStatusIds = new Set<string>();

    // Collect workflow_status_ids from all relevant keys
    Object.entries(navToQueryParams).forEach(([k, v]) => {
      if (k.endsWith("-in-progress") || k.endsWith("-inbox")) {
        const filters = isFunction(v) ? v(userId) : v;
        if (filters?.workflow_status_id) {
          if (Array.isArray(filters.workflow_status_id)) {
            filters.workflow_status_id.forEach((id) =>
              workflowStatusIds.add(id),
            );
          } else {
            workflowStatusIds.add(filters.workflow_status_id);
          }
        }
      }
    });

    // Create combined filters
    if (workflowStatusIds.size > 0) {
      const baseFilters = isFunction(navToQueryParams[key])
        ? navToQueryParams[key](userId)
        : (navToQueryParams[key] ?? null);
      additionFilters = {
        ...(baseFilters || {}),
        workflow_status_id: Array.from(workflowStatusIds),
      };
    } else {
      additionFilters = isFunction(navToQueryParams[key])
        ? navToQueryParams[key](userId)
        : (navToQueryParams[key] ?? null);
    }
  } else {
    additionFilters = isFunction(navToQueryParams?.[key])
      ? navToQueryParams[key](userId)
      : (navToQueryParams?.[key] ?? null);
  }

  // Default values for sort configuration
  const defaultSortField = "created_time";
  const defaultSortDirection = "desc";

  let advancedSearchFields = {
    ...(additionFilters && typeof additionFilters === "object"
      ? additionFilters
      : {}),
    ...omit(searchValues, TEXT_SEGMENT_KEY),
    ...(searchValues[TEXT_SEGMENT_KEY]
      ? { search: searchValues[TEXT_SEGMENT_KEY] }
      : {}),
    sort_by: sortConfig?.field || defaultSortField,
    sort_order: sortConfig?.direction || defaultSortDirection,
  };

  if (!teamId && !slug && pingId) {
    advancedSearchFields = {
      ...advancedSearchFields,
      id: pingId,
    };
  }

  // Only include cursorId if we haven't changed slugs and it's not null
  const queryParams = {
    fields: PING_VISION_DEFAULT_FIELDS,
    advancedSearchFields,
    realTimeSubscriptions: { teams: teams || [] },
    cursorId:
      slug === slugRef.current && teamId === teamIdRef.current
        ? currentCursorId
        : null,
  };

  return useGetSubmissionsQuery(queryParams, {
    skip: shouldSkip,
    // Force a refetch whenever the args change, including cursor, sort type, sort direction, etc
    refetchOnMountOrArgChange: true,
  });
};

export const useGetSingleSubmission = (
  pingId: string | null,
  additionalFilters: object = {},
  options: { skip?: boolean } = {},
) => {
  return useGetSingleSubmissionQuery(
    {
      id: pingId || "",
      ...additionalFilters,
      fields: PING_VISION_DEFAULT_FIELDS,
    },
    { skip: options.skip || !pingId },
  );
};
