interface NavQueryParam {
  workflow_status_id?: number[];
  [key: string]: any;
}

/**
 * Checks if a workflow status ID belongs to any inbox or in-progress state
 */
export const isWorkflowStatusInInboxOrProgress = (
  workflowStatusId: number,
  navToQueryParams: Record<string, any>,
): boolean => {
  return Object.entries(navToQueryParams).some(([key, value]) => {
    if (key.endsWith("-inbox") || key.endsWith("-in-progress")) {
      const param = value as NavQueryParam;
      return param?.workflow_status_id?.includes(workflowStatusId);
    }
    return false;
  });
};

/**
 * Builds a map of workflow status IDs to their inbox types
 */
export const buildWorkflowToInboxMap = (
  teamId: string | null,
  navToQueryParams: Record<string, any>,
): Record<number, string> => {
  const workflowToInboxMap: Record<number, string> = {};

  const teamPrefix = `${teamId}-`;
  Object.entries(navToQueryParams).forEach(([key, value]) => {
    if (key.startsWith(teamPrefix)) {
      const inboxType = key.replace(teamPrefix, "");
      const param = value as NavQueryParam;
      if (
        param?.workflow_status_id &&
        Array.isArray(param.workflow_status_id)
      ) {
        param.workflow_status_id.forEach((statusId: number) => {
          workflowToInboxMap[statusId] = inboxType;
        });
      }
    }
  });

  return workflowToInboxMap;
};

/**
 * Checks if a workflow status ID is mismatched with the current inbox
 */
export const hasWorkflowMismatch = (
  workflowStatusId: number,
  inboxSlug: string | null,
  navToQueryParams: Record<string, any>,
  teamId: string | null,
): boolean => {
  // For my-issues, we only care if the status is in any inbox or in-progress state
  if (inboxSlug === "my-issues") {
    return !isWorkflowStatusInInboxOrProgress(
      workflowStatusId,
      navToQueryParams,
    );
  }

  // For other views, check if the status belongs to the current inbox
  const workflowToInboxMap = buildWorkflowToInboxMap(teamId, navToQueryParams);
  const expectedInbox = workflowToInboxMap[workflowStatusId];
  const allowedSlugs = ["all", expectedInbox];

  return expectedInbox ? !allowedSlugs.includes(inboxSlug || "") : false;
};
