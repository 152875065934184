import { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "utils/redux";
import { toast } from "react-hot-toast";

import {
  DOCUMENT_TYPE,
  SovDataType,
  SovDataTypeDocument,
} from "ts-types/DataTypes";
import {
  initiateDownload,
  PingDataGrid,
  SelectColumnNoSelectAll,
  PingButton,
} from "@repo/ping-react-js";
import { format, parseISO } from "date-fns";
import { useAuth } from "utils/hooks";
import { UnknownIcon, ExcelIcon, PdfIcon } from "./PVIcons";
import { setSelectDocuments } from "reducers/document";
import {
  useProcessFilesMutation,
  useUploadDocumentMutation,
} from "services/pvSlice";
import { SiteHeaderActionButton } from "@repo/ping-react-js";

import { SelectColumn } from "react-data-grid";

import "./PVAttachmentsTable.scss";

type RenderCellProps = { row: SovDataTypeDocument };

const PVAttachmentTable = ({ selectedItem }: { selectedItem: SovDataType }) => {
  const [processFiles, { isLoading }] = useProcessFilesMutation();
  const dispatch = useAppDispatch();
  const { accessToken } = useAuth();
  const [rows, setRow] = useState<SovDataTypeDocument[]>([]);
  const selectedDocuments = useAppSelector(
    (state) => state.document.selectedDocuments,
  );

  const parseFiles = useCallback(async () => {
    if (!selectedItem.id || !selectedDocuments.length) {
      return;
    }

    await processFiles({
      id: selectedItem.id,
      filenames: selectedDocuments?.map((d) => d.filename),
    });
  }, [selectedItem, selectedDocuments, processFiles]);

  useEffect(() => {
    if (selectedItem?.documents) {
      setRow([
        ...selectedItem.documents.filter(
          (d) =>
            d.document_type !== "EML" &&
            d.document_type !== "Email Correspondence" &&
            !d.is_archived,
        ),
      ]);
    }
  }, [selectedItem]);

  const onClickDownload = useCallback(
    (attachment: { url: string; filename: string }) => {
      initiateDownload(
        import.meta.env.VITE_APP_ENV === "local"
          ? attachment.url.replace("8001", "80")
          : attachment.url,
        accessToken,
        attachment.filename,
      );
    },
    [accessToken],
  );

  const onSelectedRowsChange = useCallback(
    (selectedRows: Set<number>) => {
      dispatch(
        setSelectDocuments(rows?.filter((r) => selectedRows?.has(r.id))),
      );
    },
    [rows, dispatch],
  );

  const columns = useMemo(() => {
    return [
      {
        ...SelectColumnNoSelectAll,
        renderCell: (props) =>
          ["SOV", "ACORD"].includes(props.row.document_type)
            ? SelectColumn?.renderCell?.(props)
            : null,
      },
      {
        key: "file_icon",
        name: "",
        width: "50px",
        resizable: true,
        renderCell: (props: RenderCellProps) => {
          return (
            <>
              {["SOV", "SOVFIXER_SCRUBBER"].includes(
                props.row.document_type,
              ) && <ExcelIcon onClick={() => onClickDownload(props.row)} />}
              {props.row.document_type === "ACORD" && (
                <PdfIcon onClick={() => onClickDownload(props.row)} />
              )}
              {!["SOV", "ACORD"].includes(props.row.document_type) && (
                <UnknownIcon onClick={() => onClickDownload(props.row)} />
              )}
            </>
          );
        },
      },
      {
        key: "created_time",
        name: "Date",
        resizable: true,
        width: "100px",
        renderCell: (props: RenderCellProps) => {
          if (!props.row.created_time) return "N/A";
          try {
            const date = parseISO(props.row.created_time);
            return format(date, "MM/dd/yyyy");
          } catch (error) {
            return "Invalid Date";
          }
        },
      },
      {
        key: "filename",
        name: "File Name",
        minWidth: "fit-content",
        resizable: true,
        renderCell: (props: RenderCellProps) => {
          return props.row.filename;
        },
      },

      {
        key: "document_type",
        name: "Type",
        minWidth: "fit-content",
        resizable: true,
        renderCell: (props: RenderCellProps) => {
          return DOCUMENT_TYPE?.[props.row.document_type];
        },
      },
    ];
  }, [onClickDownload]);

  return (
    <>
      {selectedItem.automated_processing_failed && (
        <div
          role="alert"
          aria-live="assertive"
          className="PVAttachmentTable__ProcessingError"
        >
          Processing of SOV failed. Reprocess existing SOV or attach a new SOV
          and then process that.
        </div>
      )}
      <PingDataGrid
        onSelectedRowsChange={onSelectedRowsChange}
        className="PVAttachmentTable__DataGrid"
        columns={columns}
        rows={rows}
        rowClass={() => "PVAttachmentTable__DataGrid__Row"}
        rowKeyGetter={(row: SovDataTypeDocument) => {
          return row.id || Math.random();
        }}
        rowHeight={30}
        headerRowHeight={30}
        enableVirtualization={false}
        style={{ height: "100%", overflow: "auto" }}
      />

      <PingButton
        disabled={!selectedDocuments.length || isLoading}
        className="PVAttachmentTable__DataGrid__Button"
        onClick={() => parseFiles()}
        label={
          isLoading
            ? "Submitting Files For Processing..."
            : "Process Selected Files"
        }
      />
    </>
  );
};

const UploadButton: React.FC<{
  selectedItem: SovDataType;
}> = ({ selectedItem }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { accessToken } = useAuth();
  const [uploadDocument] = useUploadDocumentMutation();

  const handleFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files || !selectedItem.id) return;

      setIsUploading(true);
      try {
        for (const file of Array.from(files)) {
          const formData = new FormData();
          formData.append("file", file);

          const response = await uploadDocument({
            accessToken,
            id: selectedItem.id.toString(),
            file: formData,
          });

          if (response?.data?.message) {
            toast.success(`${file.name} uploaded successfully`);
          }
        }
      } catch (error) {
        console.error("Error uploading files:", error);
        toast.error("Failed to upload files");
      } finally {
        setIsUploading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    },
    [selectedItem.id, uploadDocument, accessToken],
  );

  const triggerFileInput = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        style={{ display: "none" }}
        multiple
        accept=".xlsx,.xls,.pdf,.csv"
      />
      {isUploading ? (
        <div className="PVAttachmentTable__UploadingText">
          Uploading Files...
        </div>
      ) : (
        <SiteHeaderActionButton
          className="PVAttachmentTable__UploadButton"
          title="Upload Files"
          iconName="attach_file"
          tooltipPlacement="left"
          onClick={triggerFileInput}
        />
      )}
    </>
  );
};

PVAttachmentTable.UploadButton = UploadButton;

export default PVAttachmentTable;
