import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import type { Middleware, Store } from "@reduxjs/toolkit";

// Base version type

interface VersionState {
  currentVersion: string | null;
  lastKnownVersion: string | null;
  wsConnected: boolean;
}

interface VersionResponse {
  version: string;
  error?: string;
}

interface VersionUpdateMessage {
  type: "version_update";
  data: {
    version: string;
  };
}

const initialState: VersionState = {
  currentVersion: null,
  lastKnownVersion: null,
  wsConnected: false,
};

// Create the version slice
export const versionSlice = createSlice({
  name: "version",
  initialState,
  reducers: {
    setCurrentVersion: (state, action: PayloadAction<string>) => {
      if (!state.lastKnownVersion) {
        state.lastKnownVersion = action.payload;
      }
      state.currentVersion = action.payload;
    },
    setWsConnected: (state, action: PayloadAction<boolean>) => {
      state.wsConnected = action.payload;
    },
  },
});

// WebSocket middleware
export const createVersionWebSocket = (): Middleware => {
  let socket: WebSocket | null = null;
  let reconnectTimeout: number;
  let reconnectAttempts = 0;
  const MAX_RECONNECT_ATTEMPTS = 5;
  const INITIAL_RECONNECT_DELAY = 1000;

  const connect = (store: Store<RootState>) => {
    if (socket?.readyState === WebSocket.OPEN) {
      console.log("Version WebSocket already connected");
      return;
    }

    const token = store.getState().auth.accessToken;
    if (!token) {
      return;
    }

    const searchParams = new URLSearchParams();
    searchParams.append("token", token);

    // Determine WebSocket URL based on current hostname and protocol
    const protocol = window.location.hostname.includes("local.pingintel")
      ? "ws"
      : "wss";
    const port = window.location.hostname.includes("local.pingintel")
      ? ":8002"
      : "";
    const wsUrl = `${protocol}://${window.location.hostname}${port}/ws/version/?${searchParams}`;

    socket = new WebSocket(wsUrl);

    socket.onopen = () => {
      console.log("Version WebSocket connected successfully");
      // Send authentication message like events websocket
      store.dispatch(versionSlice.actions.setWsConnected(true));
      reconnectAttempts = 0; // Reset reconnect attempts on successful connection
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data) as VersionUpdateMessage;
        if (data.type === "version_update" && data.data?.version) {
          store.dispatch(
            versionSlice.actions.setCurrentVersion(data.data.version),
          );
        }
      } catch (error) {
        console.error("Error parsing Version WebSocket message:", error);
      }
    };

    socket.onclose = (event) => {
      console.log("Version WebSocket closed:", event.code, event.reason);
      store.dispatch(versionSlice.actions.setWsConnected(false));

      // Clean up the socket
      socket = null;

      // Try to reconnect if not a normal closure and within max attempts
      if (event.code !== 1000 && reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
        const delay = Math.min(
          INITIAL_RECONNECT_DELAY * Math.pow(2, reconnectAttempts),
          30000,
        );
        console.log(
          `Attempting reconnect in ${delay}ms (attempt ${reconnectAttempts + 1}/${MAX_RECONNECT_ATTEMPTS})`,
        );
        reconnectTimeout = window.setTimeout(() => {
          reconnectAttempts++;
          connect(store);
        }, delay);
      } else if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
        console.log("Max reconnection attempts reached");
      }
    };

    socket.onerror = (error) => {
      console.error("Version WebSocket error:", error);
      if (socket) {
        socket.close();
      }
    };
  };

  // Return middleware function
  return (store) => (next) => (action) => {
    // Connect when auth token becomes available
    if (action.type === "auth/setAccessToken") {
      console.log(
        "Auth token received, initiating Version WebSocket connection",
      );
      // Clear any existing connection
      if (socket) {
        socket.close();
        socket = null;
      }
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
      // Reset reconnect attempts when intentionally reconnecting
      reconnectAttempts = 0;
      connect(store);
    }
    return next(action);
  };
};

// Export selectors
export const selectHasNewVersion = (state: RootState) => {
  const { currentVersion, lastKnownVersion } = state.version;
  return (
    currentVersion && lastKnownVersion && currentVersion !== lastKnownVersion
  );
};

// Export reducer
export const versionReducer = versionSlice.reducer;

// Keep the RTK Query API for HTTP fallback
export const versionApi = createApi({
  reducerPath: "versionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/",
  }),
  endpoints: (builder) => ({
    getVersion: builder.query<VersionResponse, void>({
      query: () => "version/",
    }),
  }),
});
